/** @jsx jsx */
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Flex, jsx, Text } from 'theme-ui';

import DownChevron from '~/assets/images/down-chevron.svg';

const CollapsiblePanel = ({ title, initiallyCollapsed = true, children }) => {
  const [collapsed, setCollapsed] = useState(initiallyCollapsed);
  const toggleCollapse = () => setCollapsed(!collapsed);

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: '100%',
        my: '2rem',
        '> table,td': {
          width: '100%',
        },
        '> table, td, th': {
          border: `1px solid #e3232a`,
          p: '1.5rem',
          textAlign: 'center',
        },
      }}
    >
      <Flex
        onClick={toggleCollapse}
        sx={{
          textAlign: 'left',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: "url('/images/blood.svg'), auto",
          '&:focus': {
            outline: 'none',
          },
        }}
      >
        <Text
          variant="link"
          sx={{
            my: ['0.4rem', '1rem'],
            width: '75%',
            textAlign: 'left',
            fontSize: [5, 9, 12],
            fontWeight: 700,
            textDecoration: 'underline',
            letterSpacing: '0.25px',
          }}
        >
          {title}
        </Text>

        <DownChevron
          sx={{
            transform: collapsed ? 'none' : 'rotate(180deg)',
            transition: 'transform 250ms ease-in-out',
            width: ['2rem', '3rem'],
          }}
        />
      </Flex>
      <Flex
        data-testid="collapsible-content"
        sx={{
          height: collapsed ? 0 : 'auto',
          transition: 'all 250ms ease',
          justifyContent: 'center',
        }}
      >
        <Text
          sx={{
            fontSize: [4, 9, 10],
            overflow: 'hidden',
          }}
        >
          {children}
        </Text>
      </Flex>
    </Flex>
  );
};

CollapsiblePanel.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  initiallyCollapsed: PropTypes.bool,
};

export default CollapsiblePanel;
