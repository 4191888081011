/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import Faq from '~/components/Pages/Faq';

const FaqPage = () => {
  const data = useStaticQuery(graphql`
    query {
      page: contentfulPage(slug: { eq: "faq" }) {
        name
        sections {
          ...ContentPageSectionFragment
          detailSections {
            title
            body {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `);
  const { page } = data;
  return (
    <Layout>
      <Metadata title="Faq" description="Frequently Asked Questions" />
      <Faq page={page} />
    </Layout>
  );
};

export default FaqPage;
