import React from 'react';
import { Box, Flex, Heading, Text } from 'theme-ui';
import RichTextRenderer from '~/components/Content/RichText';
import CollapsiblePanelChevron from '~/components/Generic/CollapsiblePanel/ChevronFaq';

const Faq = ({ page }) => {
  const { sections } = page;
  const { heading, subheading, copy, detailSections } = sections[0];
  return (
    <Flex sx={{ flexDirection: 'column', px: ['3rem', '7rem', '10rem'], mb: '5rem' }}>
      <Heading as="h1" variant="h2">
        <RichTextRenderer richText={heading} />
      </Heading>
      <Heading
        as="h3"
        variant="h3"
        sx={{
          fontStyle: 'italic',
        }}
      >
        <RichTextRenderer richText={subheading} />
      </Heading>
      <Text
        sx={{
          fontSize: ['1.5rem', '1.75rem'],
          '& a': { textTransform: 'none', letterSpacing: 'normal' },
        }}
      >
        <RichTextRenderer richText={copy} />
      </Text>
      {detailSections.map((detailSection) => (
        <CollapsiblePanelChevron title={detailSection.title} titleColor="alizarinCrimson">
          <Box
            dangerouslySetInnerHTML={{
              __html: detailSection.body.childMarkdownRemark.html,
            }}
            sx={{
              fontSize: [9, 10, 11],
              fontWeight: 300,
              a: { textDecoration: 'underline' },
            }}
          />
        </CollapsiblePanelChevron>
      ))}
    </Flex>
  );
};

export default Faq;
